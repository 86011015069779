/**
  WARNING!!! This file should only be altered for boilerplate.
  Changes will be propagated to all sites.
**/

import * as React from "react";
import Layout from "../components/Layout";
import { graphql, navigate, useStaticQuery } from "gatsby";
import blogHomepageConstants from "../constants/blogHomepageConstants";
import { BannerConstants } from "../constants/strapiComponentConstants";
import BlogSlider from "../components/organisms/BlogSlider";
import TrendingArticles from "../components/organisms/TrendingArticles";
import PopularCategories from "../components/organisms/PopularCategories";
import { Helmet } from "react-helmet";
import StaticBanner from "../components/molecules/StaticBanner";
import Container from "@mui/material/Container";
import BannerDiscord from "../components/molecules/banners/BannerDiscord";
import BannerMarketplace from "../components/molecules/banners/BannerMarketplace";

const { themePaths, themePageUrlPaths } = require("../../paths.js");
// markup
const Blog = ({ slug, pageContext }) => {
  const {
    strapiBlogHomepage,
    allStrapiBlogArticle,
    allStrapiCategory,
    strapiBannerDiscord,
    strapiBannerMarketplace,
  } = useStaticQuery(graphql`
    query {
      strapiBlogHomepage {
        title
        seo {
          metaTitle
          metaDescription
        }
      }
      allStrapiBlogArticle(
        filter: { publishedAt: { ne: null } }
        sort: { order: DESC, fields: updatedAt }
        limit: 8
      ) {
        nodes {
          id
          createdAt
          description
          featured_image {
            url
          }
          title
          slug
          article {
            data {
              article
            }
          }
          author {
            strapi_id
            username
            image {
              url
            }
          }
          blog_category {
            name
            slug
          }
        }
      }
      allStrapiCategory(
        filter: {
          blog_articles: { elemMatch: { publishedAt: { ne: "null" } } }
        }
        limit: 4
      ) {
        nodes {
          id
          featured_image {
            url
          }
          name
          slug
        }
      }
      strapiBannerDiscord {
        id
        image {
          url
        }
        title
        subtext
        url
        logo {
          url
        }
      }
      strapiBannerMarketplace {
        id
        image {
          url
        }
        title
        subtext
        button_text
        url
        subtextb
        logo {
          url
        }
      }
    }
  `);

  const handleLoadMoreArticles = () => {
    navigate(`/${themePaths.blog}${themePageUrlPaths.allBlogs}`);
  };

  return (
    <Layout>
      <div>
        <Helmet>
          <meta name="title" content={strapiBlogHomepage.seo.metaTitle} />
          <meta
            name="description"
            content={strapiBlogHomepage.seo.metaDescription}
          />

          {/* OpenGraph tags */}
          <meta
            property="og:title"
            content={`${process.env.GATSBY_SITE_NAME} | ${strapiBlogHomepage.title}`}
          />
          <meta
            property="og:description"
            content={`${process.env.GATSBY_SITE_NAME} | ${strapiBlogHomepage.title}`}
          />
          <meta property="og:type" content="website" />
          <meta
            property="og:image"
            content="https://artefy-strapi-boilerplate-4mdevelopment.s3.ap-southeast-2.amazonaws.com/metal_plus_97826b79e2.png"
          />
          <meta property="og:image:width" content="400" />
          <meta property="og:image:height" content="50" />

          {/* Twitter Card tags */}
          <meta
            name="twitter:card"
            content={`${process.env.GATSBY_SITE_NAME} | ${strapiBlogHomepage.title}`}
          />
          <meta
            name="twitter:creator"
            content={`${process.env.GATSBY_TWITTER_HANDLE}`}
          />
          <meta
            name="twitter:title"
            content={`${process.env.GATSBY_SITE_NAME} | ${strapiBlogHomepage.title}`}
          />
          <meta
            name="twitter:description"
            content={`${process.env.GATSBY_SITE_NAME} | ${strapiBlogHomepage.title}`}
          />
          <title>{`${process.env.GATSBY_SITE_NAME} | ${strapiBlogHomepage.title}`}</title>
        </Helmet>
        {pageContext.blocks.map((element, index) => {
          switch (element.__component) {
            case blogHomepageConstants.components.bannerSlider:
              return <BlogSlider key={element.id} />;
            case blogHomepageConstants.components.staticBanner:
              return (
                <StaticBanner
                  key={element.id}
                  bannerData={element}
                  index={index}
                />
              );
            case blogHomepageConstants.components.bannerArticle:
              return (
                <Container
                  maxWidth="xl"
                  className="mt-24 mb-24"
                  key={element.id}
                >
                  <div className="xl:flex xl:w-full xl:h-full xl:px-32 pxl:y-10 xl:items-start xl:justify-between">
                    <TrendingArticles
                      blog_articles={allStrapiBlogArticle.nodes}
                      darkmode={false}
                      loadMoreArticles={handleLoadMoreArticles}
                    />
                  </div>
                </Container>
              );
            case blogHomepageConstants.components.blogCategories:
              return (
                <Container
                  maxWidth="lg"
                  className="mt-24 mb-24"
                  key={element.id}
                >
                  <PopularCategories
                    blog_categories={allStrapiCategory.nodes}
                  />
                </Container>
              );
            case BannerConstants.marketplace:
              return element.show ? (
                <BannerMarketplace
                  bannerData={strapiBannerMarketplace}
                  key={element.id}
                />
              ) : (
                false
              );
            case BannerConstants.joinCommunity:
              return element.show ? (
                <BannerDiscord
                  bannerData={strapiBannerDiscord}
                  key={element.id}
                />
              ) : (
                false
              );
            default:
              return false;
          }
        })}
      </div>
    </Layout>
  );
};

export default Blog;
