import * as React from "react";
import Carousel from "react-material-ui-carousel";
import BlogSliderCard from "../molecules/BlogSliderCard";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CircleIcon from "@mui/icons-material/Circle";
import { graphql, useStaticQuery } from "gatsby";
import { Container } from "@mui/material";
import useWindowSize from "../../hooks/useWindowSize";

//https://github.com/Learus/react-material-ui-carousel
//Strapi does not provide array option for multi data type elements hence no map function used.

export default function BlogSlider(props) {
  const { width: screenWidth } = useWindowSize();
  const {
    allStrapiBlogArticle: { nodes: trendingArticles },
  } = useStaticQuery(graphql`
    query {
      allStrapiBlogArticle(
        limit: 5
        filter: { publishedAt: { ne: null } }
        sort: { order: DESC, fields: updatedAt }
      ) {
        nodes {
          description
          article {
            data {
              article
            }
          }
          author {
            username
          }
          blog_category {
            name
            slug
          }
          createdAt
          featured_image {
            url
          }
          id
          slug
          title
        }
      }
    }
  `);

  return (
    <Container maxWidth="xl" className="my-12">
      <Carousel
        className=" h-[735px] sm:h-[900px] lg:h-[530px]"
        swipe={true}
        animation="fade"
        duration={100}
        indicators={true}
        navButtonsAlwaysVisible={true}
        stopAutoPlayOnHover={false}
        navButtonsAlwaysInvisible={screenWidth < 1024 ? true : false}
        fullHeightHover={false}
        navButtonsProps={{
          style: {
            backgroundColor: "transparent",
            size: "large",
          },
        }}
        NextIcon={<ArrowForwardIosIcon fontSize="large" />}
        PrevIcon={<ArrowBackIosIcon fontSize="large" />}
        IndicatorIcon={<CircleIcon style={{ fontSize: "10px" }} />} // Previous Example
        indicatorIconButtonProps={{
          style: {
            color: "rgba(255, 255, 255, 0.2)",
            padding: "30px 6px 0 6px",
          },
        }}
        activeIndicatorIconButtonProps={{
          style: {
            color: "#E02424",
          },
        }}
      >
        {trendingArticles.map((article) => {
          return <BlogSliderCard key={article.id} article={article} />;
        })}
        {/* <BlogSliderCard
          key={trendingArticles[2].id}
          article={trendingArticles[2]}
        /> */}
      </Carousel>
    </Container>
  );
}
