const blogHomepageConstants = {
  components: {
    slider: "visual.slider",
    staticBanner: "visual.static-banner",
    blogArticles: "relationships.b-a",
    blogCategories: "relationships.blog-categories",
    marketplaceBanner: "defined-banners.marketplace-banner",
    discordBanner: "defined-banners.discord-banner",
    bannerSlider: "banners.slider",
    bannerArticle: "banners.articles",
  },
};

export default blogHomepageConstants;
