import { Link } from "gatsby";
import React from "react";
import Button from "../atoms/Button";
const { themePaths } = require("../../../paths.js");

const BlogSliderCard = ({ article }) => {
  const articleDate = new Date(article?.createdAt).toLocaleString("en-us", {
    month: "long",
    day: "numeric",
    year: "numeric",
  });
  return (
    <div className="flex w-full items-center lg:items-start gap-x-16 justify-between sm:max-w-[80%] mx-auto flex-col lg:flex-row gap-y-8">
      <div
        className="w-[326px] h-[326px] sm:w-[474px] sm:h-[474px] bg-cover bg-center"
        style={{
          backgroundImage: `url(${process.env.GATSBY_ASSET_URL}${article?.featured_image?.url})`,
        }}
      ></div>
      <div className="lg:w-1/2 md:w-full flex flex-col gap-y-2 lg:items-start">
        <p className="mb-4">
          <span className="text-fadedText mr-2">Posted:</span>
          {articleDate}
        </p>
        <h2
          style={{
            display: "-webkit-box",
            WebkitLineClamp: "1",
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            height: "3rem",
          }}
          className="text-5xl"
        >
          {article?.title}
        </h2>
        <p
          style={{
            display: "-webkit-box",
            WebkitLineClamp: "4",
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
          }}
          className="mb-4"
        >
          {article?.description}
        </p>
        <p className="mb-4">
          <span className="text-fadedText mr-2">By:</span>
          {article?.author?.username}
        </p>
        <Link
          to={`/${themePaths.blog}${article?.blog_category.slug}/${article?.slug}`}
          className="flex-grow"
        >
          <Button className="w-full" arrow={true}>
            View Article
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default BlogSliderCard;
